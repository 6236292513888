<template>
  <div v-if="showModuloForm === false">
    <MarketingModules></MarketingModules>
  </div>
  <div class="page" v-else-if="showModuloForm === true">
    <Panel lineColor="var(--primary-color)">
      <template v-slot:options>
        <FormButtonGroup align="end" spaced>
          <FormButton
            v-if="!order"
            text="Habilitar ordenação"
            type="primario"
            @click=";(order = true), (setoresOld = setores)"
          />
          <template v-else>
            <FormButton
              text="Salvar ordenação"
              type="success"
              @click="salvarOrdenacao"
            />
            <FormButton
              text="Cancelar ordenação"
              type="error"
              @click=";(order = false), (setores = setoresOld)"
            />
          </template>
          <FormButton
            text="Nova categoria"
            type="primario"
            @click="onNew(false)"
          />
        </FormButtonGroup>
      </template>
      <v-treeview
        v-if="!order"
        :items="items"
        :item-key="setorKey"
        :item-text="setorText"
        :load-children="getSetoresFilho"
        open-on-click
        :open.sync="open"
        color="primary"
        class="text-start"
        hoverable
      >
        <template v-slot:prepend="{ item }">
          <div class="ml-2"></div>
        </template>
        <template v-slot:append="{ item }">
          <FormButtonMenu
            icon
            :options="tableOptions"
            :loading="loadingRow[item.id]"
            @click="op => selectOption(item, op)"
          >
            <v-icon size="15">fas fa-ellipsis-v</v-icon>
          </FormButtonMenu>
        </template>
      </v-treeview>
      <Draggable v-else v-model="setores" @start="drag = true" @end="onEnd">
        <transition-group
          type="transition"
          name="flip-list"
          class="containerListProduct"
        >
          <div
            class="containerSort"
            v-for="item in setores"
            :key="item.id"
            :id="item.id"
          >
            <div class="sortable text-start">
              <v-icon size="15" class="mx-4">fa-sort</v-icon>
              {{ item.rotulo }}
              <v-chip
                class="float-right font-weight-bold"
                small
                color="primary"
                >{{ parseInt(item.ordenacao) + 1 }}</v-chip
              >
            </div>
          </div>
        </transition-group>
      </Draggable>
    </Panel>
    <transition name="slide-popup" mode="out-in">
      <router-view class="view" @close="onClose"></router-view>
    </transition>
  </div>
</template>

<script>
import Panel from '@/components/Panel'
import Draggable from 'vuedraggable'
import FormButton from '@/components/form/FormButton'
import FormButtonMenu from '@/components/form/FormButtonMenu'
import FormButtonGroup from '@/components/form/FormButtonGroup'
import MarketingModules from '@/components/MarketingModules'
import { mapGetters } from 'vuex'
import { ModulesEnum } from '@/store/actions/modulesEnum'
import {
  CATEGORIA_LOAD_RAIZ,
  CATEGORIA_LOAD_CHILDREN,
  CATEGORIA_REMOVE,
  CATEGORIA_ORDENACAO
} from '@/store/actions/categoria'
import { SEGMENTO_LIST } from '@/store/actions/segmento'

export default {
  name: 'CategoriaTable',
  components: {
    Panel,
    FormButton,
    FormButtonMenu,
    FormButtonGroup,
    MarketingModules,
    Draggable
  },
  data: () => ({
    order: false,
    isLoading: false,
    tableOptions: [
      { text: 'Deletar', value: 'delete' },
      { text: 'Editar', value: 'update' },
      { text: 'Adicionar Categoria', value: 'insert' }
    ],
    loadingRow: {},
    segmentos: [],
    segmentoSelecionado: {},
    show: {
      modal: 0,
      selecaoSegmento: false
    },
    open: [],
    setores: [],
    setoresOld: [],
    setorKey: 'id',
    setorText: 'rotulo',
    modulosForm: [
      ModulesEnum.ORCAMENTO_WEB,
      ModulesEnum.ORCAMENTO_APP,
      ModulesEnum.VENDAMAIS_WEB,
      ModulesEnum.VENDAMAIS_APP,
      ModulesEnum.WEB_COMMERCE,
      ModulesEnum.COMMERCE_APP
    ]
  }),
  computed: {
    ...mapGetters(['getFornecedorId', 'moduloFornecedorAtivo']),
    endpoint() {
      return `/api/v4/categoria/listForTable/${this.getFornecedorId}`
    },
    showModuloForm() {
      return this.moduloFornecedorAtivo(this.modulosForm)
    },
    items() {
      return this.setores
    }
  },
  methods: {
    onClick(item) {
      this.$router.push(`setores-produto/${item.id}`)
    },
    onNew(item) {
      if (item) {
        this.$router.push({
          path: 'setores-produto/novo',
          query: { idsetorpai: item.id }
        })
      } else {
        this.$router.push('setores-produto/novo')
      }
    },
    onClose(value) {
      this.loadingRow = {}
      if (value) {
        setTimeout(() => {
          window.location.reload()
        }, 300)
      }
    },
    selectOption(item, option) {
      switch (option.value) {
        case 'delete':
          return this.onDelete(item)
        case 'update':
          return this.onClick(item)
        case 'insert':
          return this.onNew(item)
      }
    },
    onDelete(item) {
      if (item.quantfilhos > 0) {
        this.$vueOnToast.pop(
          'error',
          'A categoria não pode ser removida, remova as subcategorias primeiro.'
        )
        return
      }
      const options = {
        text: 'Essa ação é irreversível.',
        title: `Você confirma a exclusão da categoria '${item.rotulo}'?`
      }
      this.confirmSwal(options, () => this.delete(item))
    },
    delete(item) {
      this.loadingRow = {
        ...this.loadingRow,
        [item.id]: true
      }
      const body = { action: 'delete', idsetor: item.id }
      this.$store
        .dispatch(CATEGORIA_REMOVE, body)
        .then(() => {
          this.$vueOnToast.pop('success', 'Categoria removida com sucesso')
          this.loadingRow = {
            ...this.loadingRow,
            [item.id]: false
          }
          window.location.reload()
        })
        .catch(() => {
          this.loadingRow = {
            ...this.loadingRow,
            [item.id]: false
          }
        })
    },
    getSegmentos() {
      this.isLoading = true
      this.$store
        .dispatch(SEGMENTO_LIST)
        .then(data => {
          this.segmentos = data
          if (this.segmentos.length === 1) {
            this.segmentoSelecionado = this.segmentos[0].idsegmento
          }
          this.isLoading = false
          this.getSetoresRaiz(this.segmentoSelecionado)
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    getSetoresRaiz(idsegmento) {
      this.isLoading = true
      this.$store
        .dispatch(CATEGORIA_LOAD_RAIZ, {
          idsegmento: idsegmento,
          promocional: true
        })
        .then(data => {
          if (data.length > 0) {
            this.setores = data.map(v => {
              let obj = {}
              if (v.quantfilhos > 0) {
                obj = { ...v, children: [] }
              } else {
                obj = v
              }
              return obj
            })
          }
          this.isLoading = false
        })
        .catch(() => {
          this.isLoading = false
        })
    },
    async getSetoresFilho(item) {
      this.isLoading = true
      if (item.children.length === 0) {
        const idsegmento = this.segmentoSelecionado
        const idsetor = item.id
        await this.$store
          .dispatch(CATEGORIA_LOAD_CHILDREN, { idsegmento, idsetor })
          .then(data => {
            const setorChildren = data.map(v => {
              let obj = {}
              if (v.quantfilhos > 0) {
                obj = { ...v, children: [] }
              } else {
                obj = v
              }
              return obj
            })
            item.children.push(...setorChildren)
            this.isLoading = false
          })
          .catch(() => {
            this.isLoading = false
          })
      }
      this.isLoading = false
    },
    onEnd() {
      this.setores = this.setores.map((item, i) => ({
        ...item,
        ordenacao: i
      }))
    },
    salvarOrdenacao() {
      const setores = this.setores.map(setor => {
        return {
          idsetor: setor.id,
          ordenacao: setor.ordenacao
        }
      })
      let body = {}
      body.setores = setores
      this.$store
        .dispatch(CATEGORIA_ORDENACAO, body)
        .then(() => {
          window.location.reload()
        })
        .catch(() => {
          this.$vueOnToast.pop(
            'error',
            'Não foi possível ordenar as categorias!'
          )
        })
    }
  },
  created() {
    this.getSegmentos()
  }
}
</script>

<style lang="scss">
.containerListProduct {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 10px;
}

.containerSort {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  border-radius: 30px;
  border: 1px solid #ccc;

  .sortable {
    text-align: center;
    width: 100%;
    padding: 0.7em;
    cursor: move;
  }
}

.flip-list-move {
  transition: transform 0.25s;
}
</style>
